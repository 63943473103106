import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CalendarSyncManagement } from './calendar-sync-management';
import { UserService } from './user.service';
import { CalendarType } from './calendar-type.enum';

@Injectable({
  providedIn: 'root'
})
export class CalendarSyncService {
  private readonly endpointUrl = environment.apiBaseUrl + '/organization/calendar-sync';

  constructor(
    private http: HttpClient,
    private userService: UserService,
  ) { }

  current(): Observable<CalendarSyncManagement> {
    return this.http.get<CalendarSyncManagement>(this.endpointUrl);
  }

  upsert(params: any): Observable<CalendarSyncManagement> {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      }
    };
    return this.http.put<CalendarSyncManagement>(this.endpointUrl, params, options);
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.endpointUrl}/${id}`);
  }

  createWithGoogleAuth(code: string, organizationName: string): Observable<CalendarSyncManagement> {
    const googleClient = this.userService.getGoogleClient(organizationName);
    const httpParams = new HttpParams()
      .append('client_id', googleClient.ClientId)
      .append('client_secret', googleClient.ClientSecret)
      .append('redirect_uri', environment.googleSettings.RedirectUri)
      .append('grant_type', environment.googleSettings.GrantType)
      .append('code', code);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      })
    };

    return this.http.post(environment.googleSettings.AccessTokenUrl, httpParams.toString(), httpOptions)
      .pipe(
        switchMap((result: { access_token: string, refresh_token: string, expires_in: number }) => {
          return this.upsert({
            calendar_type: CalendarType.Google,
            access_token: result.access_token,
            refresh_token: result.refresh_token,
            token_expire_in: result.expires_in
          });
        })
      );
  }

  createWithMicrosoftAuth(code: string, codeVerifier: string): Observable<CalendarSyncManagement> {
    const httpParams = new HttpParams()
      .append('client_id', environment.microsoftCalendarSettings.ClientId)
      .append('redirect_uri', environment.microsoftCalendarSettings.RedirectUri)
      .append('grant_type', environment.microsoftCalendarSettings.GrantType)
      .append('code', code)
      .append('code_verifier', codeVerifier);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      })
    };

    return this.http.post(environment.microsoftCalendarSettings.AccessTokenUrl, httpParams.toString(), httpOptions)
      .pipe(
        switchMap((result: { access_token: string, refresh_token: string, expires_in: number }) => {
          return this.upsert({
            calendar_type: CalendarType.Microsoft,
            access_token: result.access_token,
            refresh_token: result.refresh_token,
            token_expire_in: result.expires_in
          });
        })
      );
  }
}