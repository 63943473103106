export enum AuthProvider {
    None = '',
    Cognito = 'cognito',
    Google = 'google',
    GoogleCalendar = 'google/calendar',
    SlackUser = 'slack',
    SlackAdmin = 'slackgeneral',
    Akerun = 'akerun',
    LineWorks = 'lineworks',
    MicrosoftEntraId = 'entraid',
    MicrosoftCalendar = 'microsoft'
}
