import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PKCEStorageService {
  private readonly STORAGE_KEY = 'pkce_code_verifier';

  setCodeVerifier(verifier: string) {
    localStorage.setItem(this.STORAGE_KEY, verifier);
  }

  getCodeVerifier(): string {
    const verifier = localStorage.getItem(this.STORAGE_KEY);
    return verifier;
  }

  clearCodeVerifier() {
    localStorage.removeItem(this.STORAGE_KEY);
  }
}