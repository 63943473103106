// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    name: 'Staging',
    production: false,
    defaultLang: 'ja',
    languages: [ {'lang': 'ja', 'label': 'japanese'}, {'lang': 'en', 'label': 'english'} ],
    version: require('../../package.json').version,

    cognitoSettings: {
        UserPoolId: 'ap-northeast-1_LMcoaKHyi',
        CognitoUrl: 'https://auth.stg-reception.ai-force.ai',
        LoginRedirectUrl: 'https://stg-reception.ai-force.ai/auth/callback/cognito',
        LogoutRedirectUrl: 'https://stg-reception.ai-force.ai/login',
        IdentityPoolId: 'ap-northeast-1:825f4f72-911f-4e7d-b424-6197b8c99e4f',
        Region: 'ap-northeast-1',
        ClientId: '7br6ivs9r8crkfp3sp5j2ka0hi',
        Endpoint: undefined,
        Storage: undefined,
    },

    slackSettings: {
        ApiUrl: 'https://slack.com/api',
        AuthorizeUrl: 'https://slack.com/oauth/authorize',
        AccessTokenUrl: 'https://slack.com/api/oauth.access',
        AuthTestUrl: 'https://slack.com/api/auth.test',
        ClientId: '305222013349.622872902305',
        ClientSecret: '66c75cd7c0af4af1763b6fa59b15a967',
        PersonalRedirectUrl: 'https://stg-reception.ai-force.ai/auth/callback/slack',
        GeneralRedirectUrl: 'https://stg-reception.ai-force.ai/auth/callback/slackgeneral',
        Scope: 'channels:read groups:read chat:write:bot',
    },

    googleSettings: {
        AuthorizeUrl: 'https://accounts.google.com/o/oauth2/auth',
        AccessTokenUrl: 'https://accounts.google.com/o/oauth2/token',
        PeopleApiUrl: 'https://people.googleapis.com/v1/people/me',
        ClientId: '276152252972-5u4alu80nl8d0nuisatjufeanvmmpj7r.apps.googleusercontent.com',
        ClientSecret: 'cHWIk6pAwVgu7C3_Pkp5KjVM',
        Scope: 'https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.events.owned https://www.googleapis.com/auth/userinfo.profile',
        ResponseType: 'code',
        ApprovalPrompt: 'force',
        AccessType: 'offline',
        RedirectUri: 'https://stg-reception.ai-force.ai/auth/callback/google',
        GrantType: 'authorization_code'
    },

    googleClientProto: {
        ClientId: '583736645484-akl4nk1ri2k8j0n2ttimejal9064hi90.apps.googleusercontent.com',
        ClientSecret: '4ED9a6syeTWMYpiLbsUEkyWv',
    },

    googleClientCarCredo: {
        ClientId: '972076014221-6v6b3e57mft97spi91m6gc5p6luvl95g.apps.googleusercontent.com',
        ClientSecret: 'r1JpQ9GfT-M1G5h5iGtntAL3',
    },

    googleClientCarBricks: {
        ClientId: '276152252972-5u4alu80nl8d0nuisatjufeanvmmpj7r.apps.googleusercontent.com',
        ClientSecret: 'cHWIk6pAwVgu7C3_Pkp5KjVM',
    },

    akerunSettings: {
        AuthorizeUrl: 'https://api.akerun.com/oauth/authorize',
        AccessTokenUrl: 'https://api.akerun.com/oauth/token',
        OrganizationsUrl: 'https://api.akerun.com/v3/organizations',
        ClientId: '762abc2c849adb143e8f8569b2ea310a423cb7c1576683b171361070689c3841',
        ClientSecret: '82ef9e68cfdd40658beb069cde4053304d8dc632b9db4db969e6eb1c8bc3b168',
        RedirectUri: 'https://stg-reception.ai-force.ai/auth/callback/akerun',
        ResponseType: "code",
        GrantType: "authorization_code"
    },

    lineworksSettings: {
        AuthorizeUrl: 'https://auth.worksmobile.com/oauth2/v2.0/authorize',
        AccessTokenUrl: 'https://auth.worksmobile.com/oauth2/v2.0/token',
        RedirectUri: 'https://stg-reception.ai-force.ai/auth/callback/lineworks',
        Scope: 'bot',
        ResponseType: "code",
        GrantType: "authorization_code"
    },

    microsoftEntraIdSettings: {
        AuthorizeUrl: 'https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize',
        AccessTokenUrl: 'https://login.microsoftonline.com/organizations/oauth2/v2.0/token',
        RedirectUri: 'https://stg-reception.ai-force.ai/auth/callback/entraid',
        graphUsersUrl: 'https://graph.microsoft.com/v1.0/users',
        ClientId: '7adbeca0-f3b5-4c87-ace0-3b18fec310fc',
        ClientSecret: '0xR8Q~~5cJ8Ghqudtm5TYJ.54V~.-xlEeX5rqcuw',
        Scope: 'offline_access User.Read.All Group.Read.All User.Read',
        ResponseType: "code",
        ResponseMode: "query",
        GrantType: "authorization_code",
        GrantTypeResresh: "refresh_token"
    },

    microsoftCalendarSettings: {
        AuthorizeUrl: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
        AccessTokenUrl: 'https://login.microsoftonline.com/common/oauth2/v2.0/token',
        ClientId: 'da75cb2f-3ff6-48ac-abcf-73b3e074fea7',
        Scope: 'https://graph.microsoft.com/Calendars.ReadWrite https://graph.microsoft.com/Calendars.ReadWrite.Shared',
        ResponseType: 'code',
        ResponseMode: 'query',
        RedirectUri: 'https://stg-reception.ai-force.ai/auth/callback/microsoft',
        GrantType: 'authorization_code'
    },

    awsConfig: {
        region: 'ap-northeast-1'
    },

    siteBaseUrl: 'https://stg-reception.ai-force.ai',
    apiBaseUrl: 'https://t5wvtyxur2.execute-api.ap-northeast-1.amazonaws.com/develop',
    rakunekoScanBaseUrl: 'https://stg.d3dxkb354ib3qu.amplifyapp.com',
// tslint:disable-next-line: max-line-length
    chromeExtensionForGoogleCalendarUri: 'https://chrome.google.com/webstore/detail/ai-force-reception-%E3%82%AB%E3%83%AC%E3%83%B3%E3%83%80%E3%83%BC%E6%8B%A1/aholmdmbdjkdmknieppkpcnllmcplppi?hl=ja',
    chromeExtensionForOutlookCalendarUri: 'https://chrome.google.com/webstore/detail/%E3%83%A9%E3%82%AF%E3%83%8D%E3%82%B3-%E3%82%AB%E3%83%AC%E3%83%B3%E3%83%80%E3%83%BC%E6%8B%A1%E5%BC%B5-for-outlook/gjnhlplklkhlnamgkhinjbclehlchooc?hl=ja&authuser=0',
    chromeExtensionForGaroonCalendarUri: 'https://chrome.google.com/webstore/detail/%E3%83%A9%E3%82%AF%E3%83%8D%E3%82%B3-%E3%82%AB%E3%83%AC%E3%83%B3%E3%83%80%E3%83%BC%E6%8B%A1%E5%BC%B5-for-garoon/mpekgkinnolkffmgecgdhbjdddppmidh?hl=ja&authuser=0',
    rakunekoContactUri: 'https://raku-neko.jp/contact/',

    gaCode: 'UA-158587331-3',
    gtmCode: 'GTM-5VJH2NB',
    stripeSecretKey: 'pk_test_51Kxo4gLlu5IdKzWMk3ZJhPXZ5TVyzMdfd7LFRNoDiecrFYzzpaZJUKe5t5lgnR7uopLBt2tqsTRC8XpCuFvCUmaW00P9Jrqv2b',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
